import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container id="faq" sx={{ pt: { xs: 4, sm: 12 }, pb: { xs: 8, sm: 16 }, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { xs: 3, sm: 6 }, }} >
      <Typography component="h2" variant="h4" color="text.primary" sx={{ width: { sm: '100%', md: '60%' }, textAlign: { sm: 'left', md: 'center' }, }} > Frequently Asked Questions </Typography>
      <Box sx={{ width: '100%' }}>
  
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header" >
            <Typography component="h3" variant="subtitle2"> How can brands initiate collaborations with creators? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }} >
              Brands can start collaborations by browsing through our extensive creator database, filtering by expertise, reach, and platform. Once you find a potential match, send them a proposal directly through our secure messaging system.
            </Typography>
          </AccordionDetails>
        </Accordion>
  
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header" >
            <Typography component="h3" variant="subtitle2"> What options do creators have for monetizing their content? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }} >
              Creators can monetize their content through direct sponsorships, affiliate marketing, and performance-based campaigns. Our platform also supports custom agreements, allowing flexibility in how you earn and collaborate.
            </Typography>
          </AccordionDetails>
        </Accordion>
  
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header" >
            <Typography component="h3" variant="subtitle2"> Can I track the performance of my campaigns on the platform? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }} >
              Absolutely! Our platform provides detailed analytics and reporting tools that allow you to monitor the performance of your campaigns in real-time. Track engagement metrics, conversion rates, and ROI directly from your dashboard.
            </Typography>
          </AccordionDetails>
        </Accordion>
  
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4d-content" id="panel4d-header" >
            <Typography component="h3" variant="subtitle2"> What support is available if I encounter issues on the platform? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }} >
              Our dedicated support team is available 24/7 to assist you with any issues or questions you may have. Contact us via live chat, email, or phone for immediate support.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );  
}
