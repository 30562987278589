import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Link } from 'react-router-dom';

const tiers = [
  {title: 'Brand', price: '0', 
    description: [ 'Create one-off missions', 'Set bounties for creators', 'Fixed 5% service comission', 'Pay us only after the job is done', ],    
    buttonText: 'Sign up for free', buttonVariant: 'outlined', },

  { title: 'Creator', subheader: 'Free forever', price: '0',
    description: [ 'View all avaliable missions', 'Participate in rewarding challenges', 'Earn fixed rewards or % of sales', 'Showcase your social impact', 'Compete with other creators', ],
    buttonText: 'Create now', buttonVariant: 'contained', },

  { title: 'Enterprise', price: '39',
    description: [ 'Create missions without comissions', 'Set % of sales bounty', 'Utilize our escrow service', 'Plans start at €39 / month', ],
    buttonText: 'Subscribe', buttonVariant: 'outlined', },
];

export default function Pricing() {
  return (
    <Container id="pricing" sx={{ pt: { xs: 4, sm: 12 }, pb: { xs: 8, sm: 16 }, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { xs: 3, sm: 6 }, }} >
      <Box sx={{ width: { sm: '100%', md: '60%' }, textAlign: { sm: 'left', md: 'center' }, }} >
        <Typography component="h2" variant="h4" color="text.primary"> Pricing </Typography>
        <Typography variant="body1" color="text.secondary">
        Our platform is free for creators. Brands can choose between one-off campaings and subscriptions. Our flexible plans ensure you only pay for what you need
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => ( <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4} >
            <Card sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 4, 
                border: tier.title === 'Creator' ? '1px solid' : undefined,
                borderColor: tier.title === 'Creator' ? 'primary.main' : undefined, 
                background: tier.title === 'Creator' ? 'linear-gradient(#033363, #021F3B)' : undefined, }} >

              <CardContent>
                <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: tier.title === 'Creator' ? 'grey.100' : '', }} >
                  <Typography component="h3" variant="h6"> {tier.title} </Typography>
                  {tier.title === 'Creator' && (
                    <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} size="small"
                      sx={{ background: (theme) => theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText', '& .MuiChip-label': { color: 'primary.dark', }, '& .MuiChip-icon': { color: 'primary.dark', }, }} /> )}
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'baseline', color: tier.title === 'Creator' ? 'grey.50' : undefined, }} >
                  <Typography component="h3" variant="h2"> €{tier.price} </Typography>
                  <Typography component="h3" variant="h6"> &nbsp; per month </Typography>
                </Box>

                <Divider sx={{ my: 2, opacity: 0.2, borderColor: 'grey.500', }} />

                {tier.description.map((line) => (
                  <Box key={line} sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center', }} >
                    <CheckCircleRoundedIcon sx={{ width: 20, color: tier.title === 'Creator' ? 'primary.light' : 'primary.main', }} />
                    <Typography component="h2" variant="subtitle2" sx={{ color: tier.title === 'Creator' ? 'grey.200' : undefined, }} > {line} </Typography>
                  </Box> ))}
              </CardContent>

              <CardActions>
                <Button fullWidth component={Link} to="/signup" variant={tier.buttonVariant} > {tier.buttonText} </Button>
              </CardActions>

            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
