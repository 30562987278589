// src/components/ProfilePicture.js
import React from 'react';
import { auth } from '../service_components/firebase';
import Avatar from '@mui/material/Avatar';

const ProfilePicture = ({ size, profileUrl }) => {
    const finalProfileUrl = profileUrl || auth.currentUser?.photoURL;

    return (
        <Avatar
            src={finalProfileUrl}
            alt={`${auth.currentUser?.displayName || 'User'}'s profile`}
            sx={{ width: size, height: size, cursor: 'pointer', "&:hover": { opacity: 0.7 } }}
        />
    );
};

export default ProfilePicture;
