import React from 'react';
import { auth } from '../service_components/firebase';
import { deleteUser} from 'firebase/auth';
import { Typography } from '@mui/material';
import { Box, Button, Grid } from '@mui/material';

function MyData ({theme}) {
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleDeleteAccount = async () => {
        if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
            try {
                const token = await auth.currentUser.getIdToken();
                const response = await fetch(`${apiUrl}/api/delete-user/`, {
                    method: 'DELETE',
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                    body: JSON.stringify({ uid: auth.currentUser.uid })
                });

                await deleteUser(auth.currentUser);

                if (response.ok) {
                    alert("Account deleted successfully.");
                    // Redirect to sign-in page
                } else { throw new Error('Failed to delete user from backend.'); }
            } catch (error) { console.error(error); alert("Failed to delete account. Please sign in again or contact support"); }
        }
    };

    return (
        <Grid container sx={{ width: "100%"}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", height: "60px", mx: { xs: 1, sm: 4 }, mt: 4 }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }} > My Data </Typography>
                <Typography variant="subtitle2"> <span style={{ color: theme.palette.primary.main }}>Profile</span> {'>'} My Data </Typography>
            </Box>

            <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}> Delete data </Typography>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: "100%" }}>
                    <Box sx={{ width: { md: '100%', xs: '100%' }, height: "280px", mr: 2, p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="body1"> You can completely remove your data here </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button variant="contained" color="error" onClick={handleDeleteAccount} sx={{ mt: 2 }}> Delete account </Button></Box>
                    </Box>
                </Box>
            </Grid>
    </Grid>
    );
}

export default MyData;