import React, { useState, useEffect } from 'react';
import { auth } from './service_components/firebase';
import Box from '@mui/material/Box';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Settings from './components/Settings';
import MyData from './components/MyData';
import Socials from './components/Socials';
import Subscriptions from './components/Subscriptions';
import { Button, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const Profile = ({ onProfilePictureUpdate }) => {
    const [displayName, setDisplayName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [activeContent, setActiveContent] = useState('settings');

    const location = useLocation();
    const theme = useTheme();

    useEffect(() => {
        const fetchUserData = async () => {
            if (auth.currentUser) {
                const token = await auth.currentUser.getIdToken();
                setDisplayName(auth.currentUser.displayName || '');
                if (location.state?.activeContent) { setActiveContent(location.state.activeContent);}
                const response = await fetch(`${apiUrl}/api/user-profile/`, { headers: { 'Authorization': `Bearer ${token}`, }, });
                    if (response.ok) { const data = await response.json(); setUserRole(data.role); } else { console.error("Failed to fetch user data"); }
            }
        };

        fetchUserData();
    }, [location]);

    const renderContent = () => {
        switch (activeContent) {
            case 'settings': return <Settings theme={theme} onProfilePictureUpdate={onProfilePictureUpdate} displayName={displayName} setDisplayName={setDisplayName} userRole={userRole}/>;
            case 'socials': return <Socials theme={theme}/>;
            case 'myData': return <MyData theme={theme}/>;
            case 'Subscriptions': return <Subscriptions theme={theme}/>;
            default: return <Settings theme={theme} onProfilePictureUpdate={onProfilePictureUpdate} displayName={displayName} setDisplayName={setDisplayName} userRole={userRole}/>;
        }
    };
    
    return (
        
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100vh', width: '100%' }}>
                {/*<Box sx={{ height: '100px', width: '100%' }}></Box>*/}
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ pt: 10, minWidth: '200px', maxWidth: '200px', minHeight: 'calc(100vh - 0px)', flexGrow: 1, flexDirection: 'column', 
                        display: { xs: 'none', md: 'flex' }, backgroundColor: "#233043", overflowY: 'auto', position: 'fixed' }}>
                        <Typography sx={{ mt: 5, mb: 2, color: '#fff', px: 5 }}> Profile </Typography>
                        <Button onClick={() => setActiveContent('settings', { from: 'settings' })} sx={{ px: 6, color: '#fff', justifyContent: 'flex-start', textTransform: 'none' }}> Settings </Button>
                        <Button onClick={() => setActiveContent('socials')} sx={{ px: 6, color: '#fff', justifyContent: 'flex-start', textTransform: 'none' }}> Socials </Button>
                        <Button onClick={() => setActiveContent('Subscriptions')} sx={{ px: 6, color: '#fff', justifyContent: 'flex-start', textTransform: 'none' }}> Subscriptions </Button>
                        <Button onClick={() => setActiveContent('myData')} sx={{ px: 6, color: '#fff', justifyContent: 'flex-start', textTransform: 'none' }}> My Data </Button>
                    </Box>
                    <Box sx={{ pt: 10, marginLeft: { xs: '0', md: '200px' }, width: {xs: '100%', md: `calc(100% - 200px)`}, minHeight: 'calc(100vh - 0px)', flexGrow: 1, backgroundColor: theme.palette.mode === 'light' ? "#f8f9fd" : "#1a2634" }}>
                        {renderContent()}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Profile;
