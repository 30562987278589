import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
    const location = useLocation();

    useEffect(() => {
        const handleHash = (hash) => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop, 
                        behavior: 'smooth'
                    });
                }
            }
        };

        handleHash(location.hash);

        return () => {
            window.removeEventListener('hashchange', () => handleHash(window.location.hash));
        };
    }, [location, location.hash]); 

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 15 }}>
            <Paper elevation={1} sx={{ p: 3 }}>
                <Typography variant="h4" gutterBottom id="policy">
                    Privacy Policy
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Last updated: 1 of May 2024
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Introduction
                </Typography>
                <Typography paragraph>
                    Abunda ("we" or "us" or "our") respects the privacy of our users ("user" or "you").
                    This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you
                    visit our website abunda.pt, including any other media form, media channel, mobile website,
                    or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy
                    policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Collection of Your Information
                </Typography>
                <List dense>
                    <ListItem>
                        <ListItemText
                            primary="Personal Data"
                            secondary="Personally identifiable information, such as your name, email address, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Derivative Data"
                            secondary="Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Financial Data"
                            secondary="Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Data from Social Networks"
                            secondary="User information from social networking sites, such as Facebook, Instagram, Twitter, etc., including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks."
                        />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    Use of Your Information
                </Typography>
                <Typography paragraph>
                    Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
                </Typography>
                <List dense>
                    <ListItem>
                        <ListItemText
                            primary="Create and manage your account."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Site to you."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Email you regarding your account or order."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Fulfill and manage purchases, orders, payments, and other transactions related to the Site."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Generate a personal profile about you to make future visits to the Site more personalized."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Increase the efficiency and operation of the Site."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Monitor and analyze usage and trends to improve your experience with the Site."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Notify you of updates to the Site."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Offer new products, services, and/or recommendations to you."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Perform other business activities as needed."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Prevent fraudulent transactions, monitor against theft, and protect against criminal activity."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Process payments and refunds."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Request feedback and contact you about your use of the Site."
                        />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    Disclosure of Your Information
                </Typography>
                <Typography paragraph>
                    We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
                </Typography>
                <List dense>
                    <ListItem>
                        <ListItemText
                            primary="By Law or to Protect Rights"
                            secondary="If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Third-Party Service Providers"
                            secondary="We may share your data with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance."
                        />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    Your Data Protection Rights
                </Typography>
                <Typography paragraph>
                    Under data protection law, you have rights including:
                </Typography>
                <List dense>
                    <ListItem>
                        <ListItemText
                            primary="Your right of access"
                            secondary="You have the right to ask us for copies of your personal information."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Your right to rectification"
                            secondary="You have the right to ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Your right to erasure"
                            secondary="You have the right to ask us to erase your personal information in certain circumstances."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Your right to restriction of processing"
                            secondary="You have the right to ask us to restrict the processing of your information in certain circumstances."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Your right to object to processing"
                            secondary="You have the right to object to the processing of your personal data in certain circumstances."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Your right to data portability"
                            secondary="You have the right to ask that we transfer the information you gave us to another organisation, or to you, in certain circumstances."
                        />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom id="delete-data">
                    How to Delete Your Data
                </Typography>
                <Typography paragraph>
                    If you wish to exercise your right to erase your personal data, you may do so by following these steps:
                </Typography>
                <Typography paragraph>
                    1. Go to your personal profile on our website.
                </Typography>
                <Typography paragraph>
                    2. Visit the "My Data" section.
                </Typography>
                <Typography paragraph>
                    3. Click on the "Delete Account" button. This will initiate the process to permanently delete your account and all associated data.
                </Typography>
                <Typography paragraph>
                    Please note that certain information may need to be retained for record-keeping purposes or to complete transactions, or when required by law.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Contact Us
                </Typography>
                <Typography paragraph>
                    If you have any questions or concerns regarding this privacy policy, please contact us at team@abunda.pt
                </Typography>
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;
