import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Switch, FormControlLabel, Box, Grid, IconButton, Tooltip, TextField } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { auth } from '../service_components/firebase';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import RefreshIcon from '@mui/icons-material/Refresh';
const apiUrl = process.env.REACT_APP_API_URL;
const redirectUri = process.env.REACT_APP_OAUTH_CALLBACK_URL;

function Socials({ theme }) {
    const [isYouTubeConnected, setYouTubeConnected] = useState(false);
    const [youtubeData, setYoutubeData] = useState({ subscribersCount: 0, videoCount: 0, viewCount: 0, expiryDate: '', channelId: '' });
    const location = useLocation();
    const [idToken, setIdToken] = useState("");

    //website
    const [isWebsiteEnabled, setWebsiteEnabled] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [isUrlValid, setIsUrlValid] = useState(true);

    const handleUrlChange = (event) => {
        const newUrl = event.target.value;
        setWebsiteUrl(newUrl);
        const isValidUrl = (url) => { try { new URL(url); return true; } catch (e) { return false; } };
        setIsUrlValid(isValidUrl(newUrl) || newUrl === '');
    };
    const handleWebsiteToggle = async () => { 
        if (isUrlValid && websiteUrl !== '' && !isWebsiteEnabled) { 
            setWebsiteEnabled(!isWebsiteEnabled); 
            await updateProfileData({ my_website: websiteUrl}); } 
        if (isWebsiteEnabled) {
            setWebsiteEnabled(!isWebsiteEnabled);
            await updateProfileData({ my_website: null}); 
        } };

    //Instagram
    const [isInstaConnected, setisInstaConnected] = useState(false);
    const [instaData, setInstaData] = useState({ channelId: '' });

    const handleInstaChange = (event) => { setInstaData({channelId: event.target.value}); };
    const handleInstaToggle = async () => { 
        if (instaData.channelId !== '' && !isInstaConnected) { 
            setisInstaConnected(!isInstaConnected); 
            await updateProfileData({ instagram_channelId: instaData.channelId}); } 
        if (isInstaConnected) {
            setisInstaConnected(!isInstaConnected);
            await updateProfileData({ instagram_channelId: null}); 
        }};

    //Twitter
    const [isTwitterConnected, setTwitterConnected] = useState(false);
    const [twitterData, setTwitterData] = useState({ channelId: '' });

    const handleTwitterChange = (event) => { setTwitterData({channelId: event.target.value}); };
    const handleTwitterToggle = async () => { 
        if (twitterData.channelId !== '' && !isTwitterConnected) { 
            setTwitterConnected(!isTwitterConnected); 
            await updateProfileData({ twitter_channelId: twitterData.channelId}); } 
        if (isTwitterConnected) {
            setTwitterConnected(!isTwitterConnected);
            await updateProfileData({ twitter_channelId: null}); 
        }};

    const fetchToken = useCallback(async () => {
        if (auth.currentUser) { const token = await auth.currentUser.getIdToken(true); setIdToken(token); } else { console.error("Failed to fetch user data"); }}, []);
    useEffect(() => { fetchToken(); }, [fetchToken]);

    const getLocalData = useCallback(async () => {
        try {
            const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
            const profileResponse = await fetch(`${apiUrl}/api/user-profile/`, { method: 'GET', headers: headers });
            const profileData = await profileResponse.json();

            if (profileResponse.ok) {
                setWebsiteUrl(profileData.my_website || '');
                setWebsiteEnabled(profileData.my_website ? true : false);
                setTwitterConnected(profileData.twitter_channelId ? true : false);
                setisInstaConnected(profileData.instagram_channelId ? true : false);
                setTwitterData({channelId: profileData.twitter_channelId || ''});
                setInstaData({channelId: profileData.instagram_channelId || ''});

                setYouTubeConnected(profileData.youtube_access_token ? true : false);
                if (profileData.youtube_access_token) {
                    setYoutubeData({
                        subscribersCount: profileData.youtube_subscribers || 0,
                        videoCount: profileData.youtube_video_count || 0,
                        viewCount: profileData.youtube_views || 0,
                        expiryDate: new Date(profileData.youtube_token_expiry).toLocaleString(),
                        channelId: profileData.youtube_channelId
                    });
                }
            } else { console.error('Failed to fetch profile data:', profileData.message); }
        } catch (error) { console.error('Error checking YouTube connection:', error); }
    }, [idToken]);
    useEffect(() => { if (idToken) { getLocalData(); }}, [idToken, getLocalData]);

    const handleYouTubeToggle = async () => { if (!isYouTubeConnected) { handleRefreshData();} else { clearLocalYoutubeData(); }};
    const handleConnectYouTube = () => {
        const clientId = '120976137224-1etuivd4mauq2outqr64g0g9jdmvuc72.apps.googleusercontent.com';
        const newredirectUri = encodeURIComponent(`${redirectUri}/oauth2callback`);
        const scope = encodeURIComponent('https://www.googleapis.com/auth/youtube.readonly');
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${newredirectUri}&response_type=token&scope=${scope}&include_granted_scopes=true&prompt=select_account`;
        window.location = authUrl;
    };
    const clearLocalYoutubeData = async () => {
        try {
            setYouTubeConnected(false);
            setYoutubeData({ subscribersCount: 0, videoCount: 0, viewCount: 0, expiryDate: '', channelId: '' });
    
            const resetData = { youtube_access_token: "", youtube_token_expiry: null, youtube_subscribers: 0, youtube_views: 0, youtube_video_count: 0, youtube_channelId: '' };
            await updateProfileData(resetData);
        } catch (error) { console.error('Error during the YouTube disconnection process:', error); }
    };
    
    const updateProfileData = useCallback(async (data) => {
        try {
            const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
            const response = await fetch(`${apiUrl}/api/update-profile/`, { method: 'POST', headers: headers, body: JSON.stringify(data) });
            const responseData = await response.json();
            if (!response.ok) { console.error('Failed to update profile:', responseData.message); }
        } catch (error) { console.error('Error updating profile:', error); }
    }, [idToken]);

    const handleRefreshData = useCallback(async () => {
        try {
            const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
            const profileResponse = await fetch(`${apiUrl}/api/user-profile/`, { method: 'GET',headers: headers });
            const profileData = await profileResponse.json();
            if (!profileResponse.ok) { console.error('Failed to fetch user profile data:', profileData.message); return; }
            const { youtube_access_token: accessToken, youtube_token_expiry: expiryTime } = profileData;
    
            const tokenExpiryDate = new Date(expiryTime);
            if (!accessToken || new Date() >= tokenExpiryDate) { handleConnectYouTube(); return; }
    
            const youtubeResponse = await fetch('https://www.googleapis.com/youtube/v3/channels?part=statistics&mine=true', { headers: new Headers({ 'Authorization': `Bearer ${accessToken}` }) });
            const youtubeData = await youtubeResponse.json();
            if (!youtubeResponse.ok) { console.error('Error fetching YouTube data:', youtubeData.error); return; }
            
            
            if (youtubeData.items && youtubeData.items.length > 0) {
                const { subscriberCount, videoCount, viewCount } = youtubeData.items[0].statistics;
                const channelId = youtubeData.items[0].id;
                setYoutubeData({ subscribersCount: subscriberCount || 0, videoCount: videoCount || 0, viewCount: viewCount || 0, expiryDate: tokenExpiryDate.toLocaleString(), channelId: channelId });
                await updateProfileData({ youtube_subscribers: subscriberCount, youtube_views: viewCount, youtube_video_count: videoCount, youtube_channelId: channelId });
            }
        } catch (error) { console.error('Error during the data refresh process:', error); }
    }, [idToken, updateProfileData]);
    useEffect(() => { if (location.state?.youTubeConnected && idToken) { handleRefreshData(); }}, [location, idToken, handleRefreshData]);

    return (
        <Grid container sx={{ width: "100%" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", height: "60px", mx: { xs: 1, sm: 4 }, mt: 4 }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }} > Socials </Typography>
                <Typography variant="subtitle2"> <span style={{ color: theme.palette.primary.main }}>Profile</span> {'>'} Socials </Typography>
            </Box>

            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', mx: { xs: 1, sm: 2 }, my: 1, p: { xs: 0, sm: 2} }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'justify', }}> Connect and activate your social accounts here by toggling the switches on. 
                Abunda will only collect data related to the number of subscribers, content uploads, and views from your accounts. Your connected accounts will be displayed on 
                the platform. You can disconnect and delete your data from Abunda by turning the switches off  </Typography>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}> My website </Typography>
                <Grid container spacing={2} alignItems="center">
                    {/*Website*/}
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Grid item xs={12} sm={3}>
                            <FormControlLabel control={<Switch checked={isWebsiteEnabled} onChange={handleWebsiteToggle} />}
                                label={<Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}> <LanguageIcon sx={{ mr: 1 }} /> Website </Box>}
                                sx={{ width: 'auto', alignItems: 'center' }} 
                                disabled={!isUrlValid || websiteUrl === ''} />
                        </Grid>
                        <Grid item xs={12} sm={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {isWebsiteEnabled ? ( <MuiLink href={websiteUrl} target="_blank" rel="noopener noreferrer" underline="hover" sx={{ ml: 2 }}> {websiteUrl} </MuiLink>
                            ) : ( <TextField sx={{ mx: 2, flexGrow: 1 }} variant="outlined" fullWidth margin="dense" value={websiteUrl} placeholder="Enter your website full URL" type="url" 
                            onChange={handleUrlChange} error={!isUrlValid} helperText={!isUrlValid && "Please enter a valid URL, like https://abunda.pt"}  />)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}> My confirmed social accounts </Typography>
                <Grid container spacing={2} alignItems="center">
                    {/*Youtube*/}
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Grid item xs={4} sm={3}>
                            <FormControlLabel control={<Switch checked={isYouTubeConnected} onChange={handleYouTubeToggle} />} 
                                label={<Box sx={{ display: 'flex', alignItems: 'center' }}> <YouTubeIcon sx={{ mr: 1 }} />YouTube</Box>} 
                                sx={{ width: 'auto', alignItems: 'center' }} />
                        </Grid>
                        <Grid item xs={7} sm={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {isYouTubeConnected && ( <>
                                <Typography sx={{ mx: 2, display: {xs: 'none', sm: 'block' } }}> Subscribers: <b>{youtubeData.subscribersCount}</b> | Videos: <b>{youtubeData.videoCount}</b> | Views: <b>{youtubeData.viewCount}</b> </Typography>
                                <MuiLink href={`https://www.youtube.com/channel/${youtubeData.channelId}`} target="_blank" rel="noopener noreferrer" underline="hover">YouTube</MuiLink> </> )}
                        </Grid>
                        <Grid item xs={1} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {isYouTubeConnected && (<Tooltip title="Refresh YouTube stats"><IconButton onClick={handleRefreshData} size="small" sx={{ ml: 2, color: "lightblue" }}><RefreshIcon /></IconButton></Tooltip>)}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: {xs: 'flex', sm: 'none' }, alignItems: 'center' }}>
                        {isYouTubeConnected && (<Typography sx={{ mx: 2 }}> 
                            Subscribers: <b>{youtubeData.subscribersCount}</b> | Videos: <b>{youtubeData.videoCount}</b> | Views: <b>{youtubeData.viewCount}</b> </Typography>)}
                    </Grid>

                    {/*Instagram*/}
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Grid item xs={4} sm={3}>
                            <FormControlLabel control={<Switch checked={isInstaConnected} onChange={handleInstaToggle} />}
                                label={<Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}> <InstagramIcon sx={{ mr: 1 }} /> Instagram </Box>}
                                sx={{ width: 'auto', alignItems: 'center' }} disabled={instaData.channelId === ''} />
                        </Grid>
                        {isInstaConnected && (<>
                        <Grid item xs={7} sm={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}> 
                            <Typography sx={{ mx: 2 }}> No data </Typography>
                            <MuiLink href={`https://www.instagram.com/${instaData.channelId}`} target="_blank" rel="noopener noreferrer" underline="hover" sx={{ ml: 2 }}> {instaData.channelId} </MuiLink> 
                        </Grid>
                        <Grid item xs={1} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Tooltip title="Refresh Instagram stats"><IconButton onClick={() => {}} size="small" sx={{ ml: 2, color: "lightblue" }}><RefreshIcon /></IconButton></Tooltip>
                        </Grid> </>)}
                        {!isInstaConnected && (
                        <Grid item xs={8} sm={9} sx={{ ml: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                             <TextField sx={{ mx: 2, flexGrow: 1 }} variant="outlined" fullWidth margin="dense" value={instaData.channelId} placeholder="Enter your instagram handle" onChange={handleInstaChange}/>
                        </Grid>)}
                    </Grid>

                    {/*Twitter*/}
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Grid item xs={4} sm={3}>
                            <FormControlLabel control={<Switch checked={isTwitterConnected} onChange={handleTwitterToggle} />}
                                label={<Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}> <TwitterIcon sx={{ mr: 1 }} /> Twitter </Box>}
                                sx={{ width: 'auto', alignItems: 'center' }} disabled={twitterData.channelId === ''} />
                        </Grid>
                        {isTwitterConnected && (<>
                        <Grid item xs={7} sm={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Typography sx={{ mx: 2 }}> No data </Typography>
                            <MuiLink href={`https://www.twitter.com/${twitterData.channelId}`} target="_blank" rel="noopener noreferrer" underline="hover" sx={{ ml: 2 }}> {twitterData.channelId} </MuiLink>
                        </Grid>
                        <Grid item xs={1} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Tooltip title="Refresh Twitter stats"><IconButton onClick={() => {}} size="small" sx={{ ml: 2, color: "lightblue" }}><RefreshIcon /></IconButton></Tooltip>
                        </Grid> </>)}
                        {!isTwitterConnected && ( 
                        <Grid item xs={8} sm={9} sx={{ ml: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <TextField sx={{ mx: 2, flexGrow: 1 }} variant="outlined" fullWidth margin="dense" value={twitterData.channelId} placeholder="Enter your Twitter name" onChange={handleTwitterChange}/>
                        </Grid>)}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Socials;
