import React, { useState, useEffect } from 'react';
import { auth } from './service_components/firebase';
import { Container, Grid, Card, CardContent, Typography, Box, Button, CardActions, CardHeader, Divider, FormControlLabel, Radio, RadioGroup, CardActionArea } from '@mui/material';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Table, TableBody, TableCell, TableHead, TableRow, LinearProgress, Tooltip  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';

const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const location = useLocation();
    const theme = useTheme();
    //const lightBlueBackground = alpha(blue[500], 0.5);
    const lightGreenBackground = alpha(green[500], 0.5);
    const userName = auth.currentUser?.displayName || "User";
    const [userRole, setUserRole] = useState('');
    const [idToken, setIdToken] = useState("");
    const [missions, setMissions] = useState([]);
    const [acceptedMissions, setAcceptedMissions] = useState([]);
    const [missionFilter, setMissionFilter] = useState('Active');
    const [openDialog, setOpenDialog] = useState(false);
    const [openCreatorsDialog, setOpenCreatorsDialog] = useState(false);
    const [currentMission, setCurrentMission] = useState(null);
    const [selectedCreatorId, setSelectedCreatorId] = useState(null);
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    const colors = {
        fireEngineRed: '#D6112E',
        orangeCrayola: '#FF6C2F',
        orangeWeb: '#FAAE3F',
        prussianBlue1: '#082338',
        prussianBlue2: '#053750',
        paynesGray: '#296177',
        blueMunsell: '#0D88A8',
        moonstone: '#20BBCD',
        electricBlue: '#5DE0E9',
        rosewood: '#6E0010'
    };

    const statusMapping = {
        All: ['published', 'in_progress', 'draft', 'expired', 'canceled', 'completed', 'deleted', 'archived'],
        Active: ['published', 'in_progress'],
        Drafts: ['draft', 'expired', 'canceled'],
        Finished: ['completed', 'deleted', 'archived']
    };
    const statusPriority = { 'in_progress': 1, 'published': 2, 'draft': 3, 'completed': 4, 'expired': 5, 'deleted': 6 };

    //const descriptions = [        "Boost Brand Visibility", "Promote Top Products", "Engage with New Audiences", "Drive Campaign Success",
    //    "Lead a Creative Promotion", "Market Exciting Innovations", "Expand Brand Reach", "Elevate Product Sales",
    //    "Enhance Customer Loyalty", "Capitalize on Market Trends", "Maximize Digital Engagement", "Strengthen Brand Reputation",
    //    "Develop Strategic Partnerships", "Optimize Market Positioning", "Reinforce Brand Messaging", "Explore New Market Opportunities"    ];
    //const rewards = [        "50€ + 10% Sales", "100€ Flat Rate", "30€ + 15% Sales", "20€ + 5% Bonus", "75€ + 20% Revenue", "60€ Direct Payment",
    //    "45€ + 10% Commission", "80€ + 25% on Sales", "90€ + 12% Revenue Share", "35€ + 20% on all leads", "120€ Upfront",
    //    "55€ + 15% of first-month sales", "70€ + 5% Lifetime Commission", "65€ + 8% from direct referrals", "85€ + 30% on first deal", "40€ + 7% Quarterly Bonus"    ];
    /*
    useEffect(() => {
        if (location.state && location.state.missionFilter) { setMissionFilter(location.state.missionFilter); } else { setMissionFilter('Active'); }
    }, [location.state]);
    */
    useEffect(() => {
        const originalWarn = console.warn;
        console.warn = (...args) => { if (typeof args[0] === 'string' && args[0].includes('<button> cannot appear as a descendant of <button>')) { return; } originalWarn(...args); };
        return () => { console.warn = originalWarn; };
      }, []);

    useEffect(() => {
        const fetchToken = async () => {
            if (auth.currentUser) {
                const token = await auth.currentUser.getIdToken(true);
                setIdToken(token);
                
                //fetch user role
                const profileResponse = await fetch(`${apiUrl}/api/user-profile/`, { headers: { 'Authorization': `Bearer ${token}`, }, });
                if (profileResponse.ok) 
                    { const data = await profileResponse.json(); setUserRole(data.role); 
            
                    if (data.role === 'creator') {
                            fetchMissions(data.role, token);
                            const intervalId = setInterval(() => { if (document.visibilityState === 'visible') { fetchMissions(data.role, token); } }, 30000);
                            return () => clearInterval(intervalId); }
                    if (data.role === 'brand') { fetchAcceptedMissions(data.role, token, missionFilter); }
                }   else { console.error("Failed to fetch user data"); }
            }
        };
        fetchToken();
        // eslint-disable-next-line
    }, [currentMission, missionFilter]);

    //dialog management
    const handleClickOpen = (mission) => {
        if (userRole === 'brand') {
            const chosenCreatorExists = mission.acceptances && mission.acceptances.some(acc => acc.creator_id === mission.chosen_creator);
            setSelectedCreatorId(chosenCreatorExists ? mission.chosen_creator : null); }
        setCurrentMission(mission);
        setOpenDialog(true);
    };
    const handleClose = () => { setOpenDialog(false); };

    //creator choice and selection
    const handleChooseCreator = (creatorId) => {
        if (selectedCreatorId === creatorId) { setSelectedCreatorId(null); setShowConfirmButton(false); } else { setSelectedCreatorId(creatorId); setShowConfirmButton(true); } };
    const confirmSelection = async () => { 
        await handleCreatorSelection(currentMission.mission_id, selectedCreatorId); 
        setShowConfirmButton(false); 
        await fetchAcceptedMissions(userRole, idToken);
    };

    //creators: fetch all missions
    const fetchMissions = async (role, token) => {
        if (role === 'creator') {
            const missionResponse = await fetch(`${apiUrl}/api/missions/list`, { headers: { 'Authorization': `Bearer ${token}` }, });
            if (missionResponse.ok) { 
                const missionsData = await missionResponse.json();
                const filteredMissions = missionsData.filter(mission => statusMapping[missionFilter].includes(mission.status) );
                setMissions(filteredMissions);
            } else { console.error("Failed to fetch missions"); } 
        }
    };

    //brands: fetch all created missions and who accepted them
    const fetchAcceptedMissions = async (role, token) => {
        if (role === 'brand') {
            const acceptedMissionsResponse = await fetch(`${apiUrl}/api/missions/list-accepted/`, { headers: { 'Authorization': `Bearer ${token}` }, });
            if (acceptedMissionsResponse.ok) { 
                const missionsData = await acceptedMissionsResponse.json(); 
                const filteredMissions = missionsData.filter(mission => statusMapping[missionFilter].includes(mission.status) );
                setAcceptedMissions(filteredMissions);
                return filteredMissions;
            } else { console.error("Failed to fetch accepted missions"); return []; } 
        }
    };

    //update mission
    const updateMission = async (mission) => {
        const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
        const response = await fetch(`${apiUrl}/api/missions/update/${mission.mission_id}/`, { method: 'POST', headers: headers, body: JSON.stringify(mission) });
      
        if (response.ok) { 
            if (mission.status !== 'completed') {
                const missionsData = await fetchAcceptedMissions(userRole, idToken);
                const updatedMission = missionsData.find(m => m.mission_id === mission.mission_id);
                if (updatedMission) { const fullUpdatedMission = { ...updatedMission, ...mission }; setCurrentMission(fullUpdatedMission); }
            if (mission.status === 'deleted') { setOpenDialog(false); }
            alert('Mission updated!'); 
        }
        } else { console.error('Failed to update mission'); }
    };

    //accept mission
    const handleMissionAcceptance = async (missionId) => {
        const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
        const response = await fetch(`${apiUrl}/api/missions/accept/${missionId}/`, { method: 'POST', headers: headers });
        
        if (response.ok) { alert('Mission accepted!'); 
            setMissions(missions.filter(mission => mission.id !== missionId));
            await fetchMissions(userRole, idToken);
        } else { alert('Failed to accept mission.'); }
    };

    //select creator
    const handleCreatorSelection = async (missionId, creatorId) => {
        const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
        const response = await fetch(`${apiUrl}/api/missions/choose/${missionId}/${creatorId}/`, { method: 'POST', headers: headers });
        
        if (response.ok) { alert('Creator chosen successfully!'); 
        } else { alert('Failed to choose creator.');}
    };

    return (
        <ThemeProvider theme={theme}>
            <Box id="titlebox" sx={{ width: '100%', height: '30vh', minHeight: '200px', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column',
                backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(rgba(206, 229, 253, 0.2), rgba(206, 229, 253, 0.7))' : 'linear-gradient(#033363, #021F3B)'}}> 
                <Container maxWidth='lg' sx={{  height: '60%', minHeight: '120px', display: 'flex', flexDirection: 'column', position: 'relative'}}> 
                    <Typography component="h1" variant="h5" sx={{ mt: 2, ml: 2 }}> Welcome, {userRole} {userName} </Typography> 
                    <Typography variant="body1" sx={{ ml: 2 }}> This is the home page of your platform </Typography>
                    {userRole === 'brand' && <Button variant="outlined" sx={{ mt: 2, position: 'absolute', top: 50, right: 24, display: { xs: 'none', sm: 'inline-flex' }}} onClick={() => navigate('/mission')}> New Mission </Button>}
                    <Box sx={{ flexGrow: 1 }} />

                    <Grid container spacing={0} sx={{ml: 2, flexGrow: 1, alignItems: 'flex-start', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        {/*<Grid item xs={false} sm={3}> <Typography variant="body1" sx={{ ml: 2 }}> Select filter: </Typography> </Grid>*/}
                        <Grid item xs={12}> 
                            <RadioGroup row name="mission_filter" value={missionFilter} onChange={(e) => setMissionFilter(e.target.value)} 
                                sx={{ alignItems: 'center', display: 'flex', flexWrap: 'nowrap', '& .MuiFormControlLabel-root': { transform: { xs: 'scale(0.8)', sm: 'scale(1)' }}}} >
                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                <FormControlLabel value="Active" control={<Radio />} label="Active" />
                                {userRole === 'brand' && <FormControlLabel value="Drafts" control={<Radio />} label="Drafts" />}
                                <FormControlLabel value="Finished" control={<Radio />} label="Finished" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Container>
            <Divider />
            </Box>
            

            <Box sx={{ width: '100%', height: '70vh', backgroundColor: theme.palette.mode === 'light' ? "#f8f9fd" : "#1a2634"}}>
            <Container component="main" maxWidth='lg'>
                <Grid container spacing={0} alignItems="left" justifyContent="left"  sx={{ alignItems: 'center' }}>
                    {userRole === 'brand' && <Button variant="outlined" sx={{ mt: 1, mx: 1, width: '100%', display: { xs: 'inline-flex', sm: 'none', md: 'none', lg: 'none', xl: 'none' }}} onClick={() => navigate('/mission')}> Create Mission </Button>}
                    {(userRole === 'brand' ? acceptedMissions : missions)
                        .sort((a, b) => {
                                if (statusPriority[a.status] < statusPriority[b.status]) return -1;
                                if (statusPriority[a.status] > statusPriority[b.status]) return 1;
                                return new Date(a.creation_time) - new Date(b.creation_time); })
                        .map((mission) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={mission.mission_id}> 
                        <Card sx={{ my: 1, mx: 1, height: '300px' }} > 
                            <CardActionArea onClick={() => handleClickOpen(mission)} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <CardHeader title={`Mission ${mission.mission_id}`} subheader={mission.mission_description} 
                                    action={ <Typography variant="caption" style={{position: 'absolute', top: 0, right: 8, padding: '8px', fontWeight: 'bold', fontSize: '0.6rem'}}> {mission.status.toUpperCase()} </Typography> }
                                    sx={{ width: '100%',  position: 'relative',  bgcolor: (theme) => {
                                                    if (mission.status === 'draft') return 'rgba(211, 211, 211, 0.3)'; 
                                                    else if (mission.status === 'published') return colors.moonstone ;
                                                    else if (mission.status === 'in_progress') return lightGreenBackground; 
                                                    else if (mission.status === 'completed') return 'rgba(0, 128, 0, 0.3)';
                                                    return 'rgba(211, 211, 211, 0.8)'; }, }}
                                    subheaderTypographyProps={{ variant: 'body2', sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '250px' } }}/>

                                {mission.status === 'published' && <Tooltip title={ <> Published: {new Date(mission.publish_time).toLocaleString()} <br /> Will be delisted: {new Date(mission.expiration_time_timer).toLocaleString()}</> }>
                                    <Box sx={{ width: '100%', height: 4 }}><LinearProgress variant="determinate" value={(new Date() - new Date(mission.publish_time)) / (new Date(mission.expiration_time_timer) - new Date(mission.publish_time)) * 100} sx={{ height: 4 }} /></Box></Tooltip>}

                                <Divider sx={{ opacity: 0.2, borderColor: 'grey.500' }} />

                                <CardContent alignitems="left" justifycontent="left" sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="body1"><b>Duration:</b> {mission.duration_of_task ? `${mission.duration_of_task / (24*60*60)} days` : 'N/A'}</Typography>
                                    {mission.reward_type === 'lump_sum' && (<Typography variant="body1"><b>Reward:</b> {Math.round(mission.total_sum).toLocaleString(undefined, { maximumFractionDigits: 0 })} €{mission.advance_sum > 0 ? `, adv: ${Math.round(mission.advance_sum).toLocaleString(undefined, { maximumFractionDigits: 0 })} €` : ''}</Typography>)}
                                    {mission.reward_type === 'percent_sales' && ( <Typography variant="body1"> <b>Reward:</b> {mission.percent_value_of_sales.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })} % of Sales </Typography> )}
                                    {mission.reward_type === 'barter' && ( <Typography variant="body1"><b>Reward:</b> Barter</Typography> )}
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}> <Typography variant="subtitle1" color='inherit' sx={{ mr: 2 }} ><b>Socials:</b></Typography>
                                        <Box sx={{ display: 'flex', gap: 1 }}> {mission.socials.map((social) => ( <Box key={social} sx={{ display: 'flex', alignItems: 'center' }}>
                                                {social === 'YouTube' && <YouTubeIcon />}
                                                {social === 'Twitter' && <TwitterIcon />}
                                                {social === 'Instagram' && <InstagramIcon />} </Box> ))} </Box> </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}> <Typography variant="subtitle1" color='inherit' sx={{ mr: 2 }} ><b>Content:</b></Typography>
                                        <Box sx={{ display: 'flex', gap: 1 }}> {mission.content_types.map((type) => ( <Box key={type} sx={{ display: 'flex', alignItems: 'center' }}>
                                                {type === 'Text' && <TextFieldsIcon />}
                                                {type === 'Images' && <ImageIcon />}
                                                {type === 'Video' && <VideocamIcon />} </Box> ))} </Box> </Box>

                                    {userRole === 'creator' && <Typography variant="body1"> <b>Brand:</b> {mission.brand}</Typography>}
                                    {userRole === 'brand' && mission.status === 'in_progress' && (<Typography variant="body1"> <b>Chosen creator:</b> {mission.chosen_creator}</Typography>)}
                                    {userRole === 'brand' && mission.status === 'published' &&
                                        <Typography variant="body2" sx={{ marginTop: 'auto', fontSize: '0.8rem' }}> {mission.acceptances.length === 0 
                                                        ? "No one has yet accepted this mission" 
                                                        : `${mission.acceptances.length} creator${mission.acceptances.length !== 1 ? 's' : ''} ha${mission.acceptances.length !== 1 ? 've' : 's'} accepted your mission`} </Typography> }
                                </CardContent>

                                <CardActions alignitems="center" justifycontent="center" sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    {userRole === 'brand' && mission.status === 'draft' && ( <Button variant="contained" color="primary" onClick={(event) => {
                                        event.stopPropagation(); const missionToUpdate = { mission_id: mission.mission_id, status: 'published' };
                                        updateMission(missionToUpdate); setMissionFilter("Active");}} sx={{ marginTop: 'auto', width: '100%' }} > Publish </Button>)}
                                    {userRole === 'creator' && mission.status === 'published' && <Button variant="contained" color="primary" disabled={mission.is_accepted} onClick={(event) => {
                                        event.stopPropagation();  handleMissionAcceptance(mission.mission_id); }} sx={{ marginTop: 'auto', width: '100%' }} > {mission.is_accepted ? 'Accepted' : 'Accept'} </Button>}
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    </Grid> ))}
                </Grid>
            </Container>
            <Container component="main" maxWidth='lg' >
                {userRole === 'brand' && <Typography variant="body1"> {`${acceptedMissions.length} missions in your view`} </Typography>}
                {userRole === 'creator' && <Typography variant="body1"> {`${missions.length} missions in your view`} </Typography>}
            </Container>
            </Box>

            {/* Mission details dialog section */}
            <Dialog open={openDialog} onClose={handleClose} aria-labelledby="dialog-title" maxWidth="xl" fullWidth={false} >
                <DialogTitle id="dialog-title"> Mission ID: {currentMission?.mission_id} 
                    <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: theme.palette.primary.main }} > <CloseIcon /> </IconButton>
                </DialogTitle>
                <Divider />

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={6}>
                            <DialogContentText>Mission details:</DialogContentText>
                            <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Aim of the Mission:</b> <span style={{ marginLeft: 8 }}>{currentMission?.aim_of_the_mission}</span> </Typography>
                            <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Description:</b> <span style={{ marginLeft: 8 }}>{currentMission?.mission_description}</span> </Typography>
                            {currentMission?.publish_time && (
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Publish Time:</b> <span style={{ marginLeft: 8 }}>{new Date(currentMission?.publish_time).toLocaleString()}</span> </Typography> )}
                            {currentMission?.expiration_time_timer && (
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Expiration Time:</b> <span style={{ marginLeft: 8 }}>{new Date(currentMission?.expiration_time_timer).toLocaleString()}</span> </Typography> )}
                            {userRole === 'brand' && currentMission?.status === 'in_progress' && ( <Typography variant="body1"><b>Chosen creator:</b> {currentMission?.chosen_creator}</Typography> )}
                            {currentMission?.mission_description_long && (
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Detailed Description:</b> <span style={{ marginLeft: 8 }}>{currentMission?.mission_description_long}</span> </Typography>)}
                        </Grid>
                        
                        <Grid item xs={12} md={12} lg={6}>
                            <DialogContentText>Additional Information:</DialogContentText>
                            {currentMission?.creator_profile && (
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Creator Profile:</b> <span style={{ marginLeft: 8 }}>{currentMission?.creator_profile}</span> </Typography>)}
                            <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Duration of Task:</b> <span style={{ marginLeft: 8 }}>{currentMission?.duration_of_task ? `${currentMission.duration_of_task / (24*60*60)} days` : 'N/A'}</span> </Typography>
                            {currentMission?.reward_type === 'lump_sum' && ( 
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Reward:</b> <span style={{ marginLeft: 8 }}>{currentMission?.total_sum?.toLocaleString(undefined, { maximumFractionDigits: 0 })} €</span> </Typography>)}
                            {currentMission?.reward_type === 'lump_sum' && currentMission?.advance_sum > 0 && ( 
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Reward advance:</b> <span style={{ marginLeft: 8 }}>{currentMission?.advance_sum?.toLocaleString(undefined, { maximumFractionDigits: 0 })} €</span> </Typography>)}
                            {currentMission?.reward_type === 'percent_sales' && (
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Percent Value of Sales:</b> <span style={{ marginLeft: 8 }}>{currentMission?.percent_value_of_sales?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })} %</span> </Typography> )}
                            {currentMission?.reward_type === 'barter' && (
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Barter Description:</b> <span style={{ marginLeft: 8 }}>{currentMission?.barter_description}</span> </Typography> )}
                            {currentMission?.reward && (
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}> <b>Reward description:</b> <span style={{ marginLeft: 8 }}>{currentMission?.reward}</span> </Typography>)}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1" color='inherit' sx={{ mr: 2 }} ><b>Socials:</b></Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}> {currentMission?.socials.map((social) => ( <Box key={social} sx={{ display: 'flex', alignItems: 'center' }}>
                                            {social === 'YouTube' && <YouTubeIcon />}
                                            {social === 'Twitter' && <TwitterIcon />}
                                            {social === 'Instagram' && <InstagramIcon />}
                                        </Box> ))} </Box> </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1" color='inherit' sx={{ mr: 2 }} ><b>Content:</b></Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}> {currentMission?.content_types.map((type) => ( <Box key={type} sx={{ display: 'flex', alignItems: 'center' }}>
                                            {type === 'Text' && <TextFieldsIcon />}
                                            {type === 'Images' && <ImageIcon />}
                                            {type === 'Video' && <VideocamIcon />}
                                        </Box> ))} </Box> </Box>
                            {currentMission?.status === 'published' && userRole === 'brand' && (
                                <Box sx={{ mt: 2 }}> <DialogContentText> {`${currentMission?.acceptances.length} creator${currentMission?.acceptances.length !== 1 ? 's' : ''} ha${currentMission?.acceptances.length !== 1 ? 've' : 's'} accepted your mission` }</DialogContentText> 
                                {currentMission?.acceptances.length !== 0 && <Button variant="contained" color="primary" onClick={() => setOpenCreatorsDialog(true)}>View Creators</Button>} </Box> )}
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {userRole === 'brand' && currentMission?.status === 'draft' && ( <>
                        <Button onClick={() => {updateMission({ mission_id: currentMission?.mission_id, status: 'deleted' }); handleClose();}} color="error"> Delete </Button>
                        <Button onClick={() => navigate(`/mission/${currentMission?.mission_id}`)} color="primary"> Edit </Button> </>)}
                    {userRole === 'brand' && currentMission?.status === 'published' && ( <>
                        <Button onClick={() => {updateMission({ mission_id: currentMission?.mission_id, status: 'canceled' }); handleClose();}} color="error"> Delist </Button> </>)}
                    {userRole === 'brand' && currentMission?.status === 'canceled' && ( <>
                        <Button onClick={() => {updateMission({ mission_id: currentMission?.mission_id, status: 'deleted' }); handleClose();}} color="error"> Delete </Button>
                        <Button onClick={() => {updateMission({ mission_id: currentMission?.mission_id, status: 'draft' }); handleClose();}} color="primary"> Make draft </Button> </>)}
                    {userRole === 'creator' && currentMission?.status === 'in_progress' && ( <>
                        <Button onClick={() => {updateMission({ mission_id: currentMission?.mission_id, status: 'completed' }); handleClose();}} color="success"> Mark completed </Button> </>)}
                    {userRole === 'brand' && currentMission?.status === 'completed' && ( <>
                        <Button onClick={() => {updateMission({ mission_id: currentMission?.mission_id, status: 'in_progress' }); handleClose();}} color="warning"> Return to "in progress" </Button> </>)}
                    <Button onClick={handleClose} color="primary"> Close </Button>
                </DialogActions>
            </Dialog>

                {/* Creator selection dialog section */}
                {userRole === 'brand' && (
                <Dialog open={openCreatorsDialog} onClose={() => setOpenCreatorsDialog(false)} aria-labelledby="creators-dialog-title" maxWidth="xl" fullWidth={false} >
                    <DialogTitle id="creators-dialog-title"> Responded creators
                        <IconButton aria-label="close" onClick={() => setOpenCreatorsDialog(false)} sx={{ position: 'absolute', right: 8, top: 8, color: theme.palette.primary.main }} > <CloseIcon /> </IconButton>
                    </DialogTitle>
                    <Divider />

                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Creator</TableCell>
                                    <TableCell align="right">Date Accepted</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentMission?.acceptances.map((acceptance) => (
                                    <TableRow key={acceptance.creator_id} selected={selectedCreatorId === acceptance.creator_id}>
                                        <TableCell component="th" scope="row" style={{ minWidth: '50px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {acceptance.creator_name}
                                        </TableCell>
                                        <TableCell align="right" style={{ minWidth: '30px', maxWidth: '800px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {new Date(acceptance.accepted_at).toLocaleString()}
                                        </TableCell>
                                        <TableCell align="right" style={{ minWidth: '40px', maxWidth: '40px' }}>
                                            <Button onClick={() => handleChooseCreator(acceptance.creator_id)} color="primary"
                                                startIcon={selectedCreatorId === acceptance.creator_id ? <CheckCircleOutlineIcon /> : null}>
                                                {selectedCreatorId === acceptance.creator_id ? "" : "Choose"}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>

                    <DialogActions>
                        {showConfirmButton && selectedCreatorId && currentMission.status === 'published' && (<Button onClick={confirmSelection} color="primary"> Confirm Choice </Button> )}
                        <Button onClick={() => setOpenCreatorsDialog(false)} color="primary"> Close </Button>
                    </DialogActions>
                </Dialog>)}
        </ThemeProvider>
        
    );
}

export default Home;
