// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBRsASg3piyubsraqViCRwRxcS-TysuNXY",
    authDomain: "abunda-53b41.firebaseapp.com",
    projectId: "abunda-53b41",
    storageBucket: "abunda-53b41.appspot.com",
    messagingSenderId: "1096385163206",
    appId: "1:1096385163206:web:a55c5740312d7c1535ccc7",
    measurementId: "G-NLWX0S24LJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);
// const analytics = getAnalytics(app);

export { auth, storage, firestore };