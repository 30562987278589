import React from 'react';
import { auth } from './service_components/firebase';
import { signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { ThemeProvider, useTheme } from '@mui/material/styles';

const SignIn = () => {
  let navigate = useNavigate();
  const theme = useTheme();

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
    .then((result) => { 

      const user = result.user;
      console.log("Firebase UID:", user.uid);

      setTimeout(() => { navigate('/home'); }, 200);
    

    }).catch((error) => { alert(error.message); }); };

  const handleSignIn = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
            await signOut(auth);
            navigate('/email-confirmation-waiting');
        } else {
          setTimeout(() => { navigate('/home'); }, 500);
        }
    } catch (error) {
        alert(error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={false} sm={4} md={7}
          sx={{ backgroundImage: `url(${require('./pics/login.jpg')})`, backgroundRepeat: 'no-repeat',
            backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover', backgroundPosition: 'center'}} />
        
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '48px', maxWidth: '360px', width: '100%' }} >

            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> <LockOutlinedIcon /> </Avatar>
            <Typography component="h1" variant="h5"> Sign in </Typography>

            <Box component="form" noValidate onSubmit={handleSignIn} sx={{ mt: 1 }}>
              <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
              <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
              <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Sign In </Button>
              <Button fullWidth variant="contained" sx={{ mt: 1, mb: 2 }} onClick={googleSignIn} > Sign in with Google </Button>
              
              <Grid container>
                <Grid item xs> <MuiLink component={Link} to="/password-reset" variant="body2"> Forgot password? </MuiLink> </Grid>
                <Grid item> <MuiLink component={Link} to="/signup" variant="body2"> Don't have an account? Sign Up </MuiLink> </Grid>
              </Grid>

            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default SignIn;
