import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../service_components/firebase';
const apiUrl = process.env.REACT_APP_API_URL;

const OAuth2Callback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const getYoutubeParamsAndCallback = async () => {
            const hash = window.location.hash;
            const params = new URLSearchParams(hash.substring(1));
            const accessToken = params.get('access_token');
            const expiresIn = params.get('expires_in');
            
            if (auth.currentUser) {
                const token = await auth.currentUser.getIdToken(true)
                if (accessToken && expiresIn) {
                    const expiryTime = new Date().getTime() + parseInt(expiresIn) * 1000;
                    const response = await fetch(`${apiUrl}/api/update-profile/`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                        body: JSON.stringify({ youtube_access_token: accessToken, youtube_token_expiry: new Date(expiryTime).toISOString() })});
                    if (response.ok) { navigate('/profile', { state: { youTubeConnected: true, activeContent: 'socials' }}); }
                } else { navigate('/profile', { state: { youTubeConnected: false, activeContent: 'settings' } }); } }
            };
        getYoutubeParamsAndCallback();
    }, [navigate]);

    return <div>Loading...</div>;
};

export default OAuth2Callback;