import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Typography, TextField, LinearProgress, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { auth } from '../service_components/firebase';
import { MenuItem, Select, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { YouTube, Twitter, Instagram, TextFields, Image, Videocam } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';


const apiUrl = process.env.REACT_APP_API_URL;

function Master() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { missionId } = useParams();

  const [idToken, setIdToken] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [scopedMission, setScopedMission] = useState(missionId ? parseInt(missionId) : null);
  const [errors, setErrors] = useState({});
  const [missionData, setMissionData] = useState({creator_profile: '', socials: [], content_types: [], reward: '', reward_type: '', advance_sum: '',
    total_sum: '', percent_value_of_sales: '', barter_description: '', mission_description: '', mission_description_long: '', aim_of_the_mission: '', 
    duration_of_task: '',  expiration_time: '' });

  //get service token
  const fetchToken = useCallback(async () => {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken(true);
      setIdToken(token);
    } else { console.error("Failed to fetch user data"); }
  }, []);
  useEffect(() => { fetchToken(); }, [fetchToken]);

  //get mission data from server
  const fetchMissionData = useCallback(async () => {
    if (!Number.isInteger(scopedMission)) { return; }
    try {
      const headers = { 'Authorization': `Bearer ${idToken}` };
      const response = await fetch(`${apiUrl}/api/missions/get/${scopedMission}/`, { method: 'GET', headers });
      const data = await response.json();
      if (response.ok) { setMissionData(data);
      } else { console.error('Failed to fetch mission data:', data.message); }
    } catch (error) { console.error('Error fetching mission data:', error); }
  }, [idToken, scopedMission]);
  useEffect(() => { if (idToken) { fetchMissionData(); } }, [idToken, fetchMissionData]);

  //upload new mission data
  const updateMissionData = useCallback(async (mission_id, newData) => {
    try {
      const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
      const response = await fetch(`${apiUrl}/api/missions/update/${mission_id}/`, { method: 'POST', headers, body: JSON.stringify(newData) });
      if (!response.ok) { console.error('Failed to update mission:', response.message); }
    } catch (error) { console.error('Error updating mission:', error); }
  }, [idToken]);

  //create mission
  const createMission = async () => { 
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
    const response = await fetch(`${apiUrl}/api/missions/create/`, { method: 'POST', headers: headers });
    const responseData = await response.json();

    if (response.ok) { 
      setScopedMission(responseData.mission_id); 
      setMissionData(prev => ({ ...prev, ...responseData }));
      alert(`Mission created successfully! Mission ID: ${responseData.mission_id}`);
      navigate(`/mission/${responseData.mission_id}`);
      return responseData.mission_id;
    } else { alert(`Failed to create mission: ${responseData.message}`); }
  };  

  const handleNext = async () => {
    let stepData = {};
    let newErrors = {};
    setErrors({});

    switch (activeStep) {
      case 0:
        if (!missionData.mission_description) { newErrors.mission_description = 'Description is required'; }
        if (!missionData.aim_of_the_mission) { newErrors.aim_of_the_mission = 'Aim of the mission is required'; }
        if (Object.keys(newErrors).length > 0) { setErrors(newErrors); return; } else { setErrors({}); }
        stepData = {
          mission_description: missionData.mission_description,
          mission_description_long: missionData.mission_description_long,
          aim_of_the_mission: missionData.aim_of_the_mission,
        };
        break;

      case 1:
        if (missionData.socials.length === 0) { newErrors.socials = 'Socials not selected'; }
        if (missionData.content_types.length === 0) { newErrors.content_types = 'Content types not selected'; }
        if (Object.keys(newErrors).length > 0) { setErrors(newErrors); return; } else { setErrors({}); }
  
        stepData = {
          creator_profile: missionData.creator_profile,
          socials: missionData.socials,
          content_types: missionData.content_types,
          duration_of_task: missionData.duration_of_task
        };
        break;
      case 2:
        if (!missionData.reward_type) { newErrors.reward_type = 'Reward type not chosen'; }
        if (missionData.reward_type === 'lump_sum') { if (!missionData.total_sum) { newErrors.total_sum = 'Total sum is required'; } }
        if (missionData.reward_type === 'percent_sales') { if (!missionData.percent_value_of_sales) { newErrors.percent_value_of_sales = 'Percent value of sales is required'; } }
        if (missionData.reward_type === 'barter') { if (!missionData.barter_description) { newErrors.barter_description = 'Barter description is not filled in'; } }
        if (Object.keys(newErrors).length > 0) { setErrors(newErrors); return; } else { setErrors({}); }
        stepData = { 
          reward: missionData.reward,
          reward_type: missionData.reward_type,
          expiration_time: missionData.expiration_time
        };

        if (missionData.reward_type === 'lump_sum') {
          stepData.advance_sum = missionData.advance_sum;
          stepData.total_sum = missionData.total_sum;
        } else if (missionData.reward_type === 'percent_sales') { stepData.percent_value_of_sales = missionData.percent_value_of_sales;
        } else if (missionData.reward_type === 'barter') { stepData.barter_description = missionData.barter_description; }
        break;
      default: break;
    }

    let mission_id = scopedMission || null;
    if (activeStep === 0 && scopedMission === null) { 
      mission_id = await createMission();
      navigate(`/mission/${mission_id}`); }
    await updateMissionData(mission_id, stepData);

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () =>  { setActiveStep((prevActiveStep) => prevActiveStep - 1); setErrors({});  };

  const steps = ['Description', 'Creator', 'Reward'];
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      /* eslint-disable */
      case 0:
        return (
          <Box>
            <TextField  id="mission_description"  label="Short description"  variant="outlined"  required fullWidth  multiline  rows={1}  error={!!errors.mission_description} inputProps={{maxLength: 40}}
              helperText="Short text that will appear on top of the mission card in search. Like: Promote our new eco-friendly product, Collaborate on a summer campaign, Showcase our latest gadget"
              value={missionData.mission_description || ''}  onChange={(e) => { setMissionData({ ...missionData, mission_description: e.target.value }); const newErrors = { ...errors }; delete newErrors.mission_description; setErrors(newErrors);}}
              InputProps={{ endAdornment: ( <InputAdornment position="end"> <Typography variant="caption" display="block" gutterBottom> {missionData.mission_description?.length || 0}/40 </Typography> </InputAdornment> ) }}/>

            <TextField  id="aim_of_the_mission" label="Aim of Mission"  variant="outlined"  required fullWidth  multiline  rows={2}  sx={{ mt: 2 }} error={!!errors.aim_of_the_mission} inputProps={{maxLength: 100}}
              helperText="Longer description focusing on what you want to achieve by this mission. Like: increase engagement, boost product sales"
              value={missionData.aim_of_the_mission || ''} onChange={(e) => { setMissionData({ ...missionData, aim_of_the_mission: e.target.value }); const newErrors = { ...errors }; delete newErrors.aim_of_the_mission; setErrors(newErrors);}}
              InputProps={{ endAdornment: ( <InputAdornment position="end"> <Typography variant="caption" display="block" gutterBottom> {missionData.aim_of_the_mission?.length || 0}/100 </Typography> </InputAdornment> ) }}/>
            
            <TextField  id="mission_description_long"  label="Long Description"  variant="outlined"  fullWidth  multiline  rows={10}  sx={{ mt: 2 }} inputProps={{maxLength: 1000}}
              helperText="Full description of your mission, it should look like technical task for a creator"
              value={missionData.mission_description_long || ''}  onChange={(e) => setMissionData({ ...missionData, mission_description_long: e.target.value })}
              InputProps={{ endAdornment: ( <InputAdornment position="end"> <Typography variant="caption" display="block" gutterBottom> {missionData.mission_description_long?.length || 0}/1000 </Typography> </InputAdornment> ) }}/>
          </Box>
        );
        case 1:
          return (
            <Box>
              <TextField id="creator_profile" label="Creator Profile" variant="outlined" fullWidth multiline rows={2} inputProps={{ maxLength: 100 }}
                helperText="Describe your ideal creator for this mission"
                value={missionData.creator_profile || ''} onChange={(e) => setMissionData({ ...missionData, creator_profile: e.target.value })} 
                InputProps={{ endAdornment: ( <InputAdornment position="end"> <Typography variant="caption" display="block" gutterBottom> {missionData.creator_profile?.length || 0}/100 </Typography> </InputAdornment> ) }}/>

              <TextField id="duration_of_task" label="Duration of Task (Days)" variant="outlined" fullWidth type="number" inputProps={{ min: 1, max: 60 }} sx={{ mt: 2 }}
                helperText="Enter in how many days should creater perform this task (1 to 60)"
                value={missionData.duration_of_task || ''} onChange={(e) => { const value = parseInt(e.target.value, 10);
                  if (value >= 1 && value <= 60) { setMissionData({ ...missionData, duration_of_task: value }); } else if (e.target.value === '') { setMissionData({ ...missionData, duration_of_task: '' }); }}}/>

              <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" color='inherit'>Socials</Typography>
                <ToggleButtonGroup value={missionData.socials || []} aria-label="socials" sx={{display: 'block', '& .Mui-selected': { backgroundColor: '#0D88A8 !important', color: '#fff', }, }}
                    onChange={(event, newValue) => { setMissionData({ ...missionData, socials: newValue }); const newErrors = { ...errors }; delete newErrors.socials; setErrors(newErrors); }}>
                  <ToggleButton value="YouTube" aria-label="YouTube">       <YouTube sx={{ mr: 1 }} />    YouTube       </ToggleButton>
                  <ToggleButton value="Twitter" aria-label="Twitter">       <Twitter sx={{ mr: 1 }} />    Twitter       </ToggleButton>
                  <ToggleButton value="Instagram" aria-label="Instagram">   <Instagram sx={{ mr: 1 }} />  Instagram     </ToggleButton>
                </ToggleButtonGroup>
                {errors.socials && <Typography variant="caption" color="error" sx={{ mt: 1 }}>{errors.socials}</Typography>}
              </Box>

              <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" color='inherit'>Content Types</Typography>
                <ToggleButtonGroup value={missionData.content_types || []} aria-label="content types" sx={{display: 'block', '& .Mui-selected': { backgroundColor: '#0D88A8 !important', color: '#fff', }, }}
                onChange={(event, newValue) => { setMissionData({ ...missionData, content_types: newValue }); const newErrors = { ...errors }; delete newErrors.content_types; setErrors(newErrors); }}>
                  <ToggleButton value="Text" aria-label="Text">            <TextFields  sx={{ mr: 1 }} /> Text          </ToggleButton>
                  <ToggleButton value="Images" aria-label="Images">        <Image       sx={{ mr: 1 }} /> Images        </ToggleButton>
                  <ToggleButton value="Video" aria-label="Video">          <Videocam    sx={{ mr: 1 }} /> Video         </ToggleButton>
                </ToggleButtonGroup>
                {errors.content_types && <Typography variant="caption" color="error" sx={{ mt: 1 }}>{errors.content_types}</Typography>}
              </Box>
            </Box>
          );
          case 2:
            return (
              <Box>
                <FormControl fullWidth variant="outlined" error={!!errors.reward_type} sx={{ mt: 2 }}>
                <InputLabel id="reward_type_label">Select reward type</InputLabel>
                  <Select id="reward_type" fullWidth variant="outlined" label="Select reward type" value={missionData.reward_type || ''}
                    onChange={(e) => { setMissionData({ ...missionData, reward_type: e.target.value }); const newErrors = { ...errors }; delete newErrors.reward_type; setErrors(newErrors); }} >
                    <MenuItem value="lump_sum">Lump Sum</MenuItem>
                    <MenuItem value="percent_sales">% of Sales</MenuItem>
                    <MenuItem value="barter">Barter</MenuItem> </Select> 
                </FormControl>
                {errors.reward_type && <Typography variant="caption" color="error">{errors.reward_type}</Typography>}

                <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                  <InputLabel id="expiration_time_label">Expiration Time</InputLabel>
                  <Select id="expiration_time" label="Expiration Time" value={missionData.expiration_time || ''}
                    onChange={(e) => setMissionData({ ...missionData, expiration_time: e.target.value })}>
                    <MenuItem value={1}>1 day from publish</MenuItem>
                    <MenuItem value={3}>3 days from publish</MenuItem>
                    <MenuItem value={7}>7 days from publish</MenuItem>
                    <MenuItem value={15}>15 days from publish</MenuItem>
                    <MenuItem value={30}>30 days from publish</MenuItem>
                  </Select>
                  <FormHelperText>Expiration time is the time mission will be searchable and creators can accept it. After this time the mission staus will change to <b>expired </b> 
                    and it will be delisted. You will be able to publish it again later, if you wish</FormHelperText>
                </FormControl>
          
                {missionData.reward_type === 'lump_sum' && ( <>
                    <TextField id="total_sum" label="Total Sum in €" variant="outlined" required fullWidth type="number" sx={{ mt: 2 }} error={!!errors.total_sum}
                      value={missionData.total_sum || ''} onChange={(e) => { setMissionData({ ...missionData, total_sum: e.target.value }); const newErrors = { ...errors }; delete newErrors.total_sum; setErrors(newErrors); }} />                     
                      {errors.total_sum && <Typography variant="caption" color="error">{errors.total_sum}</Typography>}
                    <TextField id="advance_sum" label="Advance Sum in €" variant="outlined" fullWidth type="number" sx={{ mt: 2 }}
                      value={missionData.advance_sum || ''} onChange={(e) => setMissionData({ ...missionData, advance_sum: e.target.value })} /> </> )}
          
                {missionData.reward_type === 'percent_sales' && (
                  <TextField id="percent_value_of_sales" label="Percent Value of Sales" variant="outlined" required fullWidth type="number" error={!!errors.percent_value_of_sales}
                    InputProps={{ inputProps: { min: 0, max: 100, step: 1 } }} sx={{ mt: 2 }}
                    value={missionData.percent_value_of_sales || ''} onChange={(e) => { setMissionData({ ...missionData, percent_value_of_sales: e.target.value }); const newErrors = { ...errors }; delete newErrors.percent_value_of_sales; setErrors(newErrors); }}/> )}
                    {errors.percent_value_of_sales && <Typography variant="caption" color="error">{errors.percent_value_of_sales}</Typography>}

                {missionData.reward_type === 'barter' && (
                  <TextField id="barter_description" label="Barter Description" variant="outlined" required fullWidth multiline rows={3} sx={{ mt: 2 }} inputProps={{ maxLength: 200 }} error={!!errors.barter_description}
                    helperText="Describe barter rules taht you want to set: what will be givend and under which conditions"
                    value={missionData.barter_description || ''} onChange={(e) => { setMissionData({ ...missionData, barter_description: e.target.value }); const newErrors = { ...errors }; delete newErrors.barter_description; setErrors(newErrors);}}
                    InputProps={{ endAdornment: ( <InputAdornment position="end"> <Typography variant="caption" display="block" gutterBottom> {missionData.barter_description?.length || 0}/200 </Typography> </InputAdornment> ) }} /> )}
                    {errors.barter_description && <Typography variant="caption" color="error">{errors.barter_description}</Typography>}

                <TextField id="reward" label="Reward Additional Description" variant="outlined" fullWidth multiline rows={3} sx={{ mt: 2 }} inputProps={{ maxLength: 200 }}
                  helperText="Describe your special conditions behind reward, if there are some"
                  value={missionData.reward || ''} onChange={(e) => setMissionData({ ...missionData, reward: e.target.value })}
                  InputProps={{ endAdornment: ( <InputAdornment position="end"> <Typography variant="caption" display="block" gutterBottom> {missionData.reward?.length || 0}/200 </Typography> </InputAdornment> ) }}/>
              </Box>
            );
      /* eslint-enable no-alert, no-console */
      default:
        return 'Unknown Step';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ pt: 15, minWidth: '200px', maxWidth: '200px', minHeight: 'calc(100vh - 0px)', flexGrow: 1, flexDirection: 'column', 
          display: { xs: 'none', md: 'flex' }, backgroundColor: "#233043", overflowY: 'auto', position: 'fixed' }}>
          {steps.map((label, index) => ( <Box key={label} 
            sx={{ color: index === activeStep ? 'white' : 'gray', textAlign: 'left', padding: 2, backgroundColor: index === activeStep ? '#0D88A8' : 'inherit' }}> {label} </Box> ))}
        </Box> 
        
        <Box sx={{ pt: 10, marginLeft: { xs: '0', md: '200px' }, width: {xs: '100%', md: `calc(100% - 200px)`}, minHeight: 'calc(100vh - 0px)', flexGrow: 1, 
          backgroundColor: theme.palette.mode === 'light' ? "#f8f9fd" : "#1a2634"}}>
          <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }} gutterBottom>  {scopedMission ? `Editing Mission ${scopedMission}` : 'New Mission'} </Typography>
            <LinearProgress variant="determinate" value={((activeStep + 1) / (steps.length + 1)) * 100} sx={{ mb: 3 }} />
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => ( <Step key={label}> <StepLabel>{label}</StepLabel> </Step> ))}
            </Stepper>
            {activeStep === steps.length ? ( <>
                <Typography sx={{ mt: 2, mb: 1 }}> Mission draft created. You can publish it right away or exit and publish later </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button onClick={async () => { if (scopedMission) { await updateMissionData(scopedMission, { status: 'published' }); navigate('/home', { state: { missionFilter: 'Actual' } }); } }} sx={{ mr: 1 }} > Publish </Button>
                  <Button onClick={() => navigate('/home', { state: { missionFilter: 'Drafts' } })} > Exit </Button>
                </Box>
              </> ) : ( <>
                <Typography component="div" sx={{ mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>Back</Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleNext} disabled={Object.keys(errors).length > 0}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
                </Box> </> )}
          </Box>  
        </Box>
      </Box>
    </ThemeProvider>
  );  
}

export default Master;
