import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Universal Access',
    description: 'Our platform offers universal access, connecting brands and creators of all sizes across diverse sectors and social networks for maximum exposure.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Campaign Diversity',
    description: 'Launch and participate in campaigns across a variety of industries, from selling products to initiating services, ensuring dynamic market engagement.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Creator Collaboration',
    description: 'Creators can join forces with brands to accomplish specific missions, expanding their audience reach and earning potential through innovative partnerships.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Rewarding Opportunities',
    description: 'Earn rewards through tailored missions, including monetary compensation, a percentage of sales, or barter deals that exchange goods and services.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Influencer Empowerment',
    description: 'Empower influencers and opinion leaders with tools that enhance their visibility and influence, driving forward personal and professional growth.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Project Launchpad',
    description: 'Use our platform as a launchpad for new projects, reaching out to a broad audience and driving engagement through strategic creator partnerships.',
  },
];


export default function Highlights() {
  return (
    <Box id="highlights" sx={{ pt: { xs: 4, sm: 12 }, pb: { xs: 8, sm: 16 }, color: 'white', background: 'linear-gradient(#033363, #021F3B)' }} >
      <Container sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { xs: 3, sm: 6 }, }} >
        
        <Box sx={{ width: { sm: '100%', md: '60%' }, textAlign: { sm: 'left', md: 'center' }, }} >
          <Typography component="h2" variant="h4"> Highlights </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Discover what sets our platform apart. Gain unmatched exposure 
          across all social networks, connect seamlessly with influencers 
          or brands, and track your campaign's success with precision.
          </Typography>
        </Box>

        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack direction="column" color="inherit" component={Card} spacing={1} useFlexGap
                sx={{ p: 3, height: '100%', border: '1px solid', borderColor: 'grey.800', backgroundColor: 'rgba(33, 33, 33, 0.5)' }} >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom> {item.title} </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}> {item.description} </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
