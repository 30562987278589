import React, { useEffect, useState } from 'react';
import { auth } from './service_components/firebase';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import ReCAPTCHA from "react-google-recaptcha";
const apiUrl = process.env.REACT_APP_API_URL;

const SignUp = ({ onUserUpdate }) => {
    let navigate = useNavigate();
    let location = useLocation();
    const [userRole, setUserRole] = useState('creator');
    const theme = useTheme();
    const [captchaValue, setCaptchaValue] = useState(null);

    const avatarSrc = userRole === 'creator' ? require('./pics/avatar_creator.jpg') : require('./pics/avatar_brand.jpg');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setUserRole(searchParams.get('role') || 'creator');
    }, [location]);

    const onCaptchaChange = value => { setCaptchaValue(value); };

    /*async function validateInviteCode(inviteCode) {
        try {
            const inviteResponse = await fetch(`${apiUrl}/api/validate-invite`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ code: inviteCode }) });
            const inviteResult = await inviteResponse.json();
            if (!inviteResponse.ok) {
                throw new Error(inviteResult.message || 'Failed to validate invite code.');
            }
            return true;
        } catch (error) { throw error; }
    }*/

    const googleSignUp = async () => {
        if (!captchaValue) { alert('Please verify that you are a human!'); return; }

        //const inviteCode = document.getElementById('inviteCode').value;

        try {
            //await validateInviteCode(inviteCode);

            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const response = await fetch(`${apiUrl}/api/create-user`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await user.getIdToken()}` },
                body: JSON.stringify({
                    uid: user.uid,
                    username: user.displayName,
                    email: user.email,
                    is_brand: userRole === 'brand',
                    is_creator: userRole === 'creator',
                    captchaToken: captchaValue,
                    //inviteCode: inviteCode
                })
            });
            if (!response.ok) throw new Error("Failed to create user in Django");

            navigate('/home');
        } catch (error) {
            alert("Error with Google sign-up: " + error.message);
        }
    };

    const handleSignUp = async (event) => {
        event.preventDefault();
        if (!captchaValue) { alert('Please verify that you are a human!'); return; }
        
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');
        const username = data.get('username');
        //const inviteCode = data.get('inviteCode');
            
        const defaultPhotoURLs = { creator: require('./pics/avatar_creator.jpg'), brand: require('./pics/avatar_brand.jpg')};
        const defaultPhotoURL = defaultPhotoURLs[userRole] || require('./pics/avatar_brand.jpg');

        // Validating the invite code
        try {
            //await validateInviteCode(inviteCode);

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await updateProfile(userCredential.user, { displayName: username, photoURL: defaultPhotoURL });
            await sendEmailVerification(userCredential.user);

            const response = await fetch(`${apiUrl}/api/create-user`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await userCredential.user.getIdToken()}` },
                body: JSON.stringify({
                    uid: userCredential.user.uid,
                    username: username,
                    email: email,
                    is_brand: userRole === 'brand',
                    is_creator: userRole === 'creator',
                    captchaToken: captchaValue,
                    //inviteCode: inviteCode
                })
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Failed to create user");
            }
            if (response.ok) { navigate('/email-confirmation-waiting');}
            } catch (error) { alert(error.message); }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundImage: theme.palette.mode === 'light'
                ? 'linear-gradient(180deg, rgba(206, 229, 253, 0.3), #FFF)'
                : 'linear-gradient(180deg, #02294F, rgba(9, 14, 16, 0))', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', }}>
                
                <Container component="main" maxWidth="xs" sx={{ height: '100vh' }}>
                    <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '48px'}} >
                    
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: theme.spacing(15), height: theme.spacing(15) }} src={avatarSrc} alt={userRole} > </Avatar>
                        <Typography component="h1" variant="h5"> Sign Up {userRole === 'creator' ? 'as a Creator' : 'as a Brand'} </Typography>
                        
                        <RadioGroup row aria-label="user role" name="userRole" value={userRole} onChange={(e) => setUserRole(e.target.value)} sx={{ mb: 2, pt: '18px' }} >
                            <FormControlLabel value="creator" control={<Radio />} label="Creator" />
                            <FormControlLabel value="brand" control={<Radio />} label="Brand" />
                        </RadioGroup>

                        <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}> <TextField required fullWidth id="username" label="Username" name="username" autoComplete="off" /> </Grid>
                                <Grid item xs={12}> <TextField required fullWidth id="email" label="Email Address" name="email" autoComplete="email" /> </Grid>
                                <Grid item xs={12}> <TextField required fullWidth name="password" label="Password" type="password" id="password" autoComplete="new-password" /> </Grid>
                                {/*<Grid item xs={12}> <TextField required fullWidth name="inviteCode" label="Invite code" id="inviteCode" autoComplete="off" /> </Grid>*/}
                                <Grid item xs={12}> <FormControlLabel control={<Checkbox value="allowExtraEmails" color="primary" />} label="I want to receive marketing emails" /> </Grid>
                            </Grid>
                            <Box key={theme.palette.mode}  sx={{ transform: { xs: 'scale(1)', sm: 'scale(1.31)' }, transformOrigin: '0 0', marginBottom: '20px', display: 'inline-block' }}>
                                <ReCAPTCHA sitekey="6LelZ8EpAAAAAPXDHwrqlAT3T2kN-zQW_g7tcxGG" onChange={onCaptchaChange} size="normal" theme={theme.palette.mode} /></Box>
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Sign Up </Button>
                            <Button fullWidth variant="contained" onClick={googleSignUp} sx={{ mt: 1, mb: 2 }} > Sign Up with Google </Button>
                            <Grid container justifyContent="flex-end"> 
                                <Grid item> <MuiLink component={Link} to="/signin" variant="body2"> Already have an account? Sign in </MuiLink> </Grid>
                            </Grid> 
                        </Box>
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default SignUp;
