import React from 'react';
import { auth } from '../service_components/firebase';
import { sendEmailVerification, reload } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

const EmailConfirmationWaiting = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const resendEmailVerification = async () => {
        if (auth.currentUser) {
            try {
                await sendEmailVerification(auth.currentUser);
                alert("Verification email sent again. Please check your inbox.");
            } catch (error) {
                console.error("Error resending email verification: ", error);
                alert("Error resending email. Please try again in 30 seconds");
            }
        }
    };

    const checkEmailVerifiedAndRedirect = async  () => {
        await reload(auth.currentUser);
        if (auth.currentUser?.emailVerified) {
            navigate('/home');
        } else {
            alert('Your email is not yet verified. Please verify your email.');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container component="main" maxWidth="xs">
                <Box sx={{marginTop: theme.spacing(15), display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
                    <Typography component="h1" variant="h5"> Email Confirmation Needed </Typography>
                    <Typography variant="body1" sx={{ mt: 2}} align="center">
                        We've sent you an email with a confirmation link. Please check your inbox and click the link to confirm your email address and complete the sign-up process.
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }} align="center"> If you haven't received the email,<br /> check your spam folder or <br /><br /> </Typography>
                    <Button variant="contained" onClick={resendEmailVerification} sx={{ mt: 1, mb: 2 }} > Resend the confirmation email </Button>
                    <Button variant="contained" onClick={checkEmailVerifiedAndRedirect} sx={{ mt: 1, mb: 2 }}>Check Email & Continue</Button>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default EmailConfirmationWaiting;
