import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

const items = [
  {
    icon: <ViewQuiltRoundedIcon  />,
    title: 'Discover Key Players',
    description: 'Easily search and connect with influential creators or leading brands. Our smart matching system helps you find the perfect partners for your projects and campaigns.',
    imageLight: `url(${require('../pics/feature_1.JPG')})`, 
    imageDark: `url(${require('../pics/feature_1.JPG')})`,
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Amplify Your Reach',
    description: 'Boost your visibility across all major social networks and platforms. Whether you are a brand or a creator, leverage our tools to increase your outreach and engagement.',
    imageLight: `url(${require('../pics/feature_2.JPG')})`,
    imageDark: `url(${require('../pics/feature_2.JPG')})`,
  },
  {
    icon: <DevicesRoundedIcon  />,
    title: 'Campaign Tracker',
    description: 'Monitor the progress and impact of your campaigns in real time. Set objectives, follow your milestones, and achieve your goals through our comprehensive tracking tools.',
    imageLight: `url(${require('../pics/feature_3.JPG')})`,
    imageDark: `url(${require('../pics/feature_3.JPG')})`,
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div> <Typography component="h2" variant="h4" color="text.primary"> Product features </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: { xs: 2, sm: 4 } }} >
            Explore our platform designed to empower brands and creators. 
            From strategic discovery tools to expansive network engagement and precise campaign 
            tracking, learn how our features can transform your digital presence
            </Typography> </div>
          <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} useFlexGap sx={{ width: '100%' }}>
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{ p: 3, height: '100%', width: '100%', background: 'none', backgroundColor: selectedItemIndex === index ? 'rgba(173, 216, 230, 0.2)' : undefined,
                  borderColor: (theme) => theme.palette.mode === 'light' ? 'primary.main' : 'primary.dark' }} >
                
                <Box sx={{ display: 'flex', textAlign: 'left', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 2.5 }}>
                  <Box sx={{ color: selectedItemIndex === index ? 'primary.main' : 'grey.300' }}> {icon} </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography color="text.primary" variant="body2" fontWeight="bold">{title}</Typography>
                    <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>{description}</Typography>
                    <Link color="primary" variant="body2" fontWeight="bold" sx={{ display: 'inline-flex', alignItems: 'center', '& > svg': { transition: '0.2s' }, 
                      '&:hover > svg': { transform: 'translateX(2px)' }, }}>
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
                    </Link>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box
              sx={{
                height: '100%', 
                width: '100%',
                backgroundImage: selectedItemIndex === 0 ? items[0].imageLight : selectedItemIndex === 1 ? items[1].imageLight : items[2].imageLight,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
