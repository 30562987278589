import React, { useState } from 'react';
import { auth } from '../service_components/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { ThemeProvider, useTheme } from '@mui/material/styles';

const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const theme = useTheme();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent. Check your inbox.");
        } catch (error) {
            console.error("Failed to send password reset email: ", error);
            alert("Failed to send password reset email.");
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box sx={{ marginTop: theme.spacing(15), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5"> Password Reset </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus 
                            value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Send Reset Email </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default PasswordReset;
