import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export default function Hero() {
  const theme = useTheme();

  return (
    <>
    <Box id="hero" sx={{ width: '100%', height: '70vh', backgroundImage: theme.palette.mode === 'light'
          ? `url(${require('../pics/background.jpg')})`
          : `url(${require('../pics/background_black.jpg')})`,
        backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}} >

      <Box sx={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'column', justifyContent: 'center', 
        alignItems: 'flex-start', padding: theme.spacing(4), paddingLeft: theme.spacing(0), 
        backgroundImage: `linear-gradient(to top, ${alpha(theme.palette.background.default, 1)} 0%, 
                                                  ${alpha(theme.palette.background.default, 0.85)} 50%, 
                                                  ${alpha(theme.palette.background.default, 0)} 100%)` }}>
        
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: '100%'  }}>
            <Box sx={{ width: { xs: '100%', md: 'auto' }, minWidth: { md: '500px' } }}>
              <Typography variant="h1" sx={{ textAlign: 'left', fontSize: { xs: '48px', md: '64px' }, color: theme.palette.mode === 'light' ? 'rgb(0, 20, 70)' : 'rgba(188, 78, 63, 255)', 
                fontFamily: '"Inter", sans-serif', fontWeight: 600 }}> Abunda </Typography>
              <Typography color="text.secondary" sx={{ fontFamily: '"Inter", sans-serif', fontSize: { xs: '18px', md: '24px'} }}> Where brands meet creators </Typography>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', mt: 2, width: '300px' }}>
              <Button component={Link} to="/signup" variant="outlined" color="primary" sx={{ fontSize: '1.25rem', width: '100%', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)' }} > Try It Now </Button>
            </Box>
          </Box>
        </Container>

      </Box>
    </Box>
    <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }, justifyContent: 'center', mt: 2, width: '100%' }}>
      <Button fullWidth component={Link} to="/signup" variant="outlined" color="primary" sx={{ fontSize: '1.25rem', width: '300px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)' }} > Try It Now </Button>
    </Box>
    </>
  );
}
