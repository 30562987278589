import React, { useEffect, useState, useCallback } from 'react';
import { auth } from '../service_components/firebase';
import { Elements } from '@stripe/react-stripe-js';
import { Grid, Box, Typography } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';

const apiUrl = process.env.REACT_APP_API_URL;
const stripePromise = loadStripe('pk_test_51MUQsxAVqMhD5pS950i7IBFqgjDCXDYgi8PUT9Yrm3Z1LDEW2mXZ99ZjTJPGuLTlFYWxvHDXHgPyUSzElfX0FUUr00VY8SOG0O');

const SubscribeForm = () => {
  const [idToken, setIdToken] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const fetchToken = useCallback(async () => {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken(true);
      setIdToken(token);
    } else {
      console.error("Failed to fetch user data");
    }
  }, []);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  const fetchSubscriptionStatus = useCallback(async () => {
    try {
      const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
      const response = await fetch(`${apiUrl}/api/subscription-status/`, { method: 'GET', headers: headers });
      const responseData = await response.json();
      if (response.ok) {
        setSubscriptionStatus(responseData.status);
        console.log(responseData.status);
      } else {
        console.error('Failed to fetch subscription status:', responseData.message);
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  }, [idToken]);

  useEffect(() => {
    if (idToken) {
      fetchSubscriptionStatus();
    }
  }, [idToken, fetchSubscriptionStatus]);

  /*
  const createCustomerAndSubscribe = async () => {
    try {
      const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
      const response = await fetch(`${apiUrl}/api/create-customer/`, { method: 'POST', headers: headers });
      const responseData = await response.json();
      if (response.ok) {
        // Now you have the customer ID and you can use Stripe Buy Button
        // Implement your Stripe Buy Button logic here
      } else {
        console.error('Failed to create customer:', responseData.message);
      }
    } catch (error) {
      console.error('Error creating customer:', error);
    }
  };

  //useEffect(() => {
  //  if (subscriptionStatus === 'no_subscription') {
  //    createCustomerAndSubscribe();
  //  }
  //}, [subscriptionStatus, createCustomerAndSubscribe]);
  */

  return (
    <Grid container sx={{ width: "100%" }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", height: "60px", mx: { xs: 1, sm: 4 }, mt: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}> Subscriptions </Typography>
        <Typography variant="subtitle2"> <span style={{ color: '#3f51b5' }}>Profile</span> {'>'} Subscriptions </Typography>
      </Box>

      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: '#fff' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}> Subscription </Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: "100%" }}>
          <Box sx={{ width: { md: '100%', xs: '100%' }, height: "280px", mr: 2, p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1"> {subscriptionStatus !== 'no_subscription' ? `You are subscribed: ${subscriptionStatus}` : 'Subscribe to our service'} </Typography>
            {subscriptionStatus === 'no_subscription' && (
              <>
                <script async src="https://js.stripe.com/v3/buy-button.js"></script>
                <stripe-buy-button
                  buy-button-id="buy_btn_1PIuzJAVqMhD5pS997OvcpnI"
                  publishable-key="pk_test_51MUQsxAVqMhD5pS950i7IBFqgjDCXDYgi8PUT9Yrm3Z1LDEW2mXZ99ZjTJPGuLTlFYWxvHDXHgPyUSzElfX0FUUr00VY8SOG0O">
                </stripe-buy-button>
              </>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const Subscriptions = () => (
  <Elements stripe={stripePromise}>
    <SubscribeForm />
  </Elements>
);

export default Subscriptions;
